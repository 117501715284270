import { createBrowserRouter } from "react-router-dom";
import {
  Cancellation,
  Community,
  Contactus,
  Home,
  Layout,
  Legal,
  Privacy,
  SignUp,
  Terms,
} from "../pages";

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        index: true,
        element: <Home />,
      },
      {
        path: "/local",
        element: <Home local={true} />,
      },
      {
        path: "/contactus",
        element: <Contactus />,
      },
      {
        path: "/cancellationpolicy",
        element: <Cancellation />,
      },
      {
        path: "/communityguidelines",
        element: <Community />,
      },
      {
        path: "/legal",
        element: <Legal />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
    ],
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
]);
