import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import successIcon from "../assets/Animation.gif";
import CloseIcon from "../Components/ContactUs/CloseIcon";
import styles from "../Components/Thankyou.module.css";

const ContactUsThankYouModal = ({ show, modalHandler }) => {
  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={styles.close} onClick={() => modalHandler(false)}>
          <CloseIcon />
        </div>
        <Modal.Body className="text-center rounded-4">
          <Row className="justify-content-center mb-3">
            <Col sm="12" md="10">
              <div className="text-center">
                <img
                  src={successIcon}
                  alt="Success Iocn"
                  style={{ width: "100px" }}
                />
              </div>
              <h4 className="fs-4 fw-semibold">Thank you for reaching out.</h4>
              <p className="px-lg-5">
                Please allow 5-10 business days for a response from Wareeg.We
                appreciate your patience.
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactUsThankYouModal;
