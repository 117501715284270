import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import styles from "../pages/SignUp.module.css";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
import { useFormik } from "formik";

const FormSignUp = ({ modalHandler }) => {
  const [err, setErr] = useState("");
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      location: "",
      userType: "local",
    },
    onSubmit: async (values) => {
      setErr("");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/earlyAccess/createEarlyAccess`,
          values,
          {
            headers: {
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            modalHandler(true);
            formik.resetForm({
              fullName: "",
              email: "",
              location: "",
              userType: "local",
            });
          } else if (res.data.status === 400) {
            console.log(res.data.error);
          } else if (res.data.status === 401) {
            // Handle unauthorized error
          }
        })
        .catch((err) => {
          setErr(err?.response?.data?.error?.message);
          //setTimeout(() => setErr(""), 2000);
        });
    },
    validateOnChange: true,
    validateOnBlur: true,

    validate: (values) => {
      let errors = {};
      if (formik.touched.fullName && !values.fullName) {
        errors.fullName = "This field is required";
      }
      if (formik.touched.email && !values.email) {
        errors.email = "This field is required";
      }
      if (formik.touched.email && values.email && !isValidEmail(values.email)) {
        errors.email = "Invalid email address";
      }
      if (!values.location) {
        errors.location = "This field is required";
      }
      return errors;
    },
  });

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <Form className="row gap-4" onSubmit={formik.handleSubmit}>
      <Form.Group className="col-12">
        <div className={styles.toggle_switch}>
          <div className="w-50">
            <input
              type="radio"
              name="userType"
              value="experiencer"
              id="experienceruser"
              hidden
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} // Ensure onBlur is added
              checked={formik.values.userType === "experiencer"}
            />
            <label htmlFor="experienceruser">Experiencer</label>
          </div>
          <div className="w-50">
            <input
              type="radio"
              name="userType"
              value="local"
              id="localuser"
              hidden
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} // Ensure onBlur is added
              checked={formik.values.userType === "local"}
            />
            <label htmlFor="localuser">Local</label>
          </div>
        </div>
      </Form.Group>
      <Form.Group className="col-12">
        <Form.Label className={styles.label}>Full Name</Form.Label>
        <Form.Control
          className={styles.input}
          type="text"
          name="fullName"
          placeholder="Eg: Mark Brown"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur} // Ensure onBlur is added
        />
        {formik.touched.fullName && formik.errors.fullName ? (
          <div className="text-danger">
            <small>{formik.errors.fullName}</small>
          </div>
        ) : null}
      </Form.Group>
      <Form.Group className="col-12">
        <Form.Label className={styles.label}>Email Address</Form.Label>
        <Form.Control
          className={styles.input}
          type="email"
          name="email"
          placeholder="markbrown@example.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur} // Ensure onBlur is added
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="text-danger">
            <small>{formik.errors.email}</small>
          </div>
        ) : null}
      </Form.Group>
      <Form.Group className="col-12">
        <Form.Label className={styles.label}>Location</Form.Label>
        <Autocomplete
          className={`${styles.input} form-control`}
          type="text"
          name="location"
          placeholder={
            formik.values.userType === "local"
              ? `Desired City to Host`
              : `Desired City to Experience`
          }
          value={formik.values.location}
          apiKey={process.env.REACT_APP_GOOGLE}
          onPlaceSelected={(place) =>
            formik.setFieldValue("location", place.formatted_address)
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur} // Ensure onBlur is added
        />
        {formik.touched.location && formik.errors.location ? (
          <div className="text-danger">
            <small>{formik.errors.location}</small>
          </div>
        ) : null}
      </Form.Group>
      {err && <div style={{ color: "red", fontSize: "12px" }}>{err}</div>}
      <Form.Group className="col-12">
        <Button
          type="submit"
          disabled={formik.isSubmitting}
          className={styles.btn}
        >
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
};

export default FormSignUp;
